import { getDbToken } from 'lib/coplay/backend/FirebaseFunctions';
import { BackendConfig } from 'lib/coplay/constants/generated';
import { Response } from 'node-fetch';
import { AggResponse, AggRequest, OauthActions, BatchRequest, DeleteResponse, FriendingRequest, QueryConsoleRequest, QueryConsoleResponse, LinkConsoleRequest, LinkConsoleResponse, RenameConsoleRequest, BasicResponse, UninstallRequest, SearchedSoftware, BatchInstallRequest, UserPrivacySettings, SettingsRequest, PartialXMProfile, XMProfileRequest, ScheduleEvent, ScheduleRequest, RemoveScheduleRequest, MintKeyRequest, MintKeyResponse, DeleteKeyRequest, XboxPower, BatchPowerRequest } from '../types/BackendTypes';
import { isCoplayResponse } from '../utils';
const BackendUrl: string = BackendConfig.url;

async function makeApiPostRequest(payload: string): Promise<Response> {
    const requestHeaders: Headers = new Headers();
    requestHeaders.append('Accept', 'application/json');
    requestHeaders.append('content-type', 'application/json');
    const response: Response = await fetch(BackendUrl, {
        method: 'POST',
        headers: requestHeaders,
        body: payload
    });

    return response;
}

export async function addNewUser(xAuthCode: string): Promise<AggResponse> {
    const payload: AggRequest = {
        oauthAction: OauthActions.Aggregate,
        xAuthCode: xAuthCode,
        firebaseToken: await getDbToken(),
        requestId: Date.now()
    }

    const response: Response = await makeApiPostRequest(JSON.stringify(payload));

    if (isCoplayResponse(response.status)) {
        return await response.json() as AggResponse;
    } else {
        const text = await response.text();
        throw new Error("Failed to add a new user: " + text);
    }
}

export async function reAggregateUser(xAuthCode: string): Promise<AggResponse> {
    const payload: AggRequest = {
        oauthAction: OauthActions.ReAggregate,
        xAuthCode: xAuthCode,
        firebaseToken: await getDbToken(),
        requestId: Date.now()
    }

    const response: Response = await makeApiPostRequest(JSON.stringify(payload));

    if (isCoplayResponse(response.status)) {
        return await response.json() as AggResponse;
    } else {
        const text = await response.text();
        throw new Error("Failed to add a new user: " + text);
    }
}

export async function refreshUsers(emailAddresses: string[]) {
    const payload: BatchRequest = {
        oauthAction: OauthActions.Refresh,
        firebaseToken: await getDbToken(),
        emailAddresses: emailAddresses,
        requestId: Date.now()
    }

    const response = await makeApiPostRequest(JSON.stringify(payload));
    if (isCoplayResponse(response.status)) {
        return await response.json()
    } else {
        const text = await response.text();
        throw new Error("Failed to refresh users: : " + text);
    }
}

export async function refreshConsoles(emailAddresses: string[]) {
    const payload: BatchRequest = {
        oauthAction: OauthActions.RefreshConsole,
        firebaseToken: await getDbToken(),
        emailAddresses: emailAddresses,
        requestId: Date.now()
    }

    const response = await makeApiPostRequest(JSON.stringify(payload));
    if (isCoplayResponse(response.status)) {
        return await response.json()
    } else {
        const text = await response.text();
        throw new Error("Failed to refresh consoles: : " + text);
    }
}

export async function clearConversations(emailAddresses: string[]) {
    const payload: BatchRequest = {
        oauthAction: OauthActions.ClearConversations,
        firebaseToken: await getDbToken(),
        emailAddresses: emailAddresses,
        requestId: Date.now()
    }

    const response = await makeApiPostRequest(JSON.stringify(payload));

    if (isCoplayResponse(response.status)) {
        return await response.json()
    } else {
        const text = await response.text();
        throw new Error("Failed to clear conversations: " + text);
    }
}

export async function deleteUsers(emailAddresses: string[]): Promise<DeleteResponse> {
    const payload: BatchRequest = {
        oauthAction: OauthActions.Delete,
        firebaseToken: await getDbToken(),
        emailAddresses: emailAddresses,
        requestId: Date.now()
    }

    const response = await makeApiPostRequest(JSON.stringify(payload));

    if (isCoplayResponse(response.status)) {
        return await response.json() as DeleteResponse;
    } else {
        const text = await response.text();
        throw new Error("Failed to refresh users: : " + text);
    }
}

export async function handleFriending(
    emailAddresses: string[],
    friendXuids: string[],
    oauthAction:
        OauthActions.AddFriends | OauthActions.RemoveFriends | OauthActions.SyncFriends) {
    const payload: FriendingRequest = {
        oauthAction: oauthAction,
        firebaseToken: await getDbToken(),
        requestId: Date.now(),
        emailAddresses: emailAddresses,
        friendXuids: friendXuids
    }

    const response = await makeApiPostRequest(JSON.stringify(payload));

    if (isCoplayResponse(response.status)) {
        return await response.json()
    } else {
        const text = await response.text();
        throw new Error("testRemoveFriends failed with: " + text);
    }
}

export async function getConsolesForUser(emailAddress: string): Promise<string[]> {
    const payload: QueryConsoleRequest = {
        oauthAction: OauthActions.QueryConsoles,
        firebaseToken: await getDbToken(),
        requestId: Date.now(),
        emailAddress: emailAddress
    }

    const response = await makeApiPostRequest(JSON.stringify(payload));

    if (isCoplayResponse(response.status)) {
        const res = await response.json() as QueryConsoleResponse;
        return res.consoleIds;
    } else {
        const text = await response.text();
        throw new Error(`Failed to get consoles for ${emailAddress}: ${text}`);
    }
}

export async function linkConsole(emailAddress: string, consoleId: string, enable: boolean = true) {
    const payload: LinkConsoleRequest = {
        oauthAction: enable ? OauthActions.LinkConsole : OauthActions.UnlinkConsole,
        firebaseToken: await getDbToken(),
        requestId: Date.now(),
        emailAddress: emailAddress,
        consoleId: consoleId
    }

    const response = await makeApiPostRequest(JSON.stringify(payload));

    if (isCoplayResponse(response.status)) {
        const res = await response.json() as LinkConsoleResponse;
        if (res.error)
            throw (res.error);
        if (res.consoleId === consoleId) {
            return res.consoleId;
        }
    } else {
        const text = await response.text();
        throw new Error(`Failed to link console ${consoleId} for ${emailAddress}: ${text}`);
    }
}

export async function renameConsole(emailAddress: string, consoleId: string, name: string) {
    const payload: RenameConsoleRequest = {
        oauthAction: OauthActions.RenameConsole,
        firebaseToken: await getDbToken(),
        requestId: Date.now(),
        emailAddress: emailAddress,
        consoleId,
        name
    }

    const response = await makeApiPostRequest(JSON.stringify(payload));

    if (isCoplayResponse(response.status)) {
        const res = await response.json() as LinkConsoleResponse;
        if (res.error)
            throw (res.error);
        if (res.consoleId === consoleId) {
            return res.consoleId;
        }
    } else {
        const text = await response.text();
        throw new Error(`Failed to link console ${consoleId} for ${emailAddress}: ${text}`);
    }
}

export async function uninstallSoftware(emailAddress: string, consoleId: string, instanceIds: string[]): Promise<BasicResponse> {
    const payload: UninstallRequest = {
        oauthAction: OauthActions.UninstallSoftware,
        firebaseToken: await getDbToken(),
        requestId: Date.now(),
        emailAddress: emailAddress,
        consoleId: consoleId,
        instanceIds: instanceIds
    }

    const response = await makeApiPostRequest(JSON.stringify(payload));

    if (isCoplayResponse(response.status)) {
        const res = await response.json() as BasicResponse;
        if (res.error)
            throw (res.error);

        return res;
    } else {
        const text = await response.text();
        throw new Error(`Failed to uninstall software ${text}`);
    }
}



export async function batchInstallSoftware(emailAddresses: string[], software: SearchedSoftware[]) {
    const payload: BatchInstallRequest = {
        oauthAction: OauthActions.BatchInstall,
        firebaseToken: await getDbToken(),
        emailAddresses: emailAddresses,
        requestId: Date.now(),
        software
    }

    const response = await makeApiPostRequest(JSON.stringify(payload));

    if (isCoplayResponse(response.status)) {
        return await response.json()
    } else {
        const text = await response.text();
        throw new Error("Failed to clear conversations: " + text);
    }
}

export async function batchTogglePower(emailAddresses: string[], powerState: XboxPower) {
    const payload: BatchPowerRequest = {
        oauthAction: OauthActions.ToggleConsolePower,
        firebaseToken: await getDbToken(),
        emailAddresses: emailAddresses,
        requestId: Date.now(),
        powerState
    }

    const response = await makeApiPostRequest(JSON.stringify(payload));

    if (isCoplayResponse(response.status)) {
        return await response.json()
    } else {
        const text = await response.text();
        throw new Error("Failed to toggle power: " + text);
    }
}

export async function changeSettings(emailAddress: string[], settings: UserPrivacySettings) {
    const token = await getDbToken();
    const payload: SettingsRequest = {
        oauthAction: OauthActions.ChangeSettings,
        firebaseToken: token,
        requestId: Date.now(),
        emailAddresses: emailAddress,
        settings: settings
    }

    const response = await makeApiPostRequest(JSON.stringify(payload));

    if (isCoplayResponse(response.status)) {
        const res = await response.json() as BasicResponse;
        if (res.error)
            throw (res.error);

        return res;
    } else {
        const text = await response.text();
        throw new Error(`Failed to Change user settings ${text}`);
    }
}

export async function changeXMProfile(emailAddress: string[], action: 'unset' | 'sync' | 'set', xmProfile: PartialXMProfile) {
    const payload: XMProfileRequest = {
        oauthAction: OauthActions.XMProfile,
        firebaseToken: await getDbToken(),
        requestId: Date.now(),
        emailAddresses: emailAddress,
        action: action,
        profile: xmProfile
    }

    const response = await makeApiPostRequest(JSON.stringify(payload));

    if (isCoplayResponse(response.status)) {
        const res = await response.json() as BasicResponse;
        if (res.error)
            throw (res.error);

        return res;
    } else {
        const text = await response.text();
        throw new Error(`Failed to change user profile ${text}`);
    }
}

export async function syncXMProfile(emailAddress: string[]) {
    const payload: XMProfileRequest = {
        oauthAction: OauthActions.XMProfile,
        firebaseToken: await getDbToken(),
        requestId: Date.now(),
        emailAddresses: emailAddress,
        action: 'sync'
    }

    const response = await makeApiPostRequest(JSON.stringify(payload));

    if (isCoplayResponse(response.status)) {
        const res = await response.json() as BasicResponse;
        if (res.error)
            throw (res.error);

        return res;
    } else {
        const text = await response.text();
        throw new Error(`Failed to change user profile ${text}`);
    }
}


export async function createFriendingRequest(emailAddresses: string[], friendXuids: string[], oauthAction: OauthActions.AddFriends | OauthActions.RemoveFriends | OauthActions.SyncFriends): Promise<FriendingRequest> {
    const token = await getDbToken();

    return {
        oauthAction: oauthAction,
        firebaseToken: token,
        requestId: Date.now(),
        emailAddresses: emailAddresses,
        friendXuids: friendXuids
    }
}


export async function createBatchRequest(
    oauthAction: OauthActions.Refresh | OauthActions.ClearConversations,
    emailAddresses: string[]
): Promise<BatchRequest> {
    const token = await getDbToken();

    return {
        oauthAction: oauthAction,
        firebaseToken: token,
        requestId: Date.now(),
        emailAddresses: emailAddresses
    }
}


export async function createSyncProfileRequest(
    emailAddresses: string[]
): Promise<XMProfileRequest> {
    const token = await getDbToken();

    return {
        oauthAction: OauthActions.XMProfile,
        firebaseToken: token,
        requestId: Date.now(),
        action: "sync",
        emailAddresses: emailAddresses
    }
}


/*
Possible Requests
- Refresh
- Sync
- Remove all 
- Clear all conversations
*/
export async function scheduleAction(scheduledEvent: ScheduleEvent) {

    const token = await getDbToken();
    const payload: ScheduleRequest = {
        oauthAction: OauthActions.AddSchedule,
        firebaseToken: token,
        requestId: Date.now(),
        scheduledEvent,
        emailAddresses: scheduledEvent.scheduledRequest.emailAddresses
    }

    console.log("Scheduling action: ", payload)

    const requestHeaders: Headers = new Headers();
    requestHeaders.append('Accept', 'application/json');
    requestHeaders.append('content-type', 'application/json');
    const response: Response = await fetch(BackendUrl, {
        method: 'POST',
        headers: requestHeaders,
        body: JSON.stringify(payload)
    });
    if (isCoplayResponse(response.status)) {
        return await response.json()
    } else {
        const text = await response.text();
        throw new Error("Failed to Schedule Action: " + text);
    }
}


export async function unscheduleAction(scheduleId: string, emailAddress?: string): Promise<BasicResponse> {
    const token = await getDbToken();
    const payload: RemoveScheduleRequest = {
        oauthAction: OauthActions.RemoveSchedule,
        firebaseToken: token,
        requestId: Date.now(),
        scheduleId: scheduleId,
        emailAddress: emailAddress ? emailAddress : null
    }

    const requestHeaders: Headers = new Headers();
    requestHeaders.append('Accept', 'application/json');
    requestHeaders.append('content-type', 'application/json');
    const response: Response = await fetch(BackendUrl, {
        method: 'POST',
        headers: requestHeaders,
        body: JSON.stringify(payload)
    });
    if (isCoplayResponse(response.status)) {
        return await response.json() as BasicResponse;
    } else {
        const text = await response.text();
        throw new Error("Failed to refresh users: : " + text);
    }
}

export async function mintApiKey(name?: string): Promise<MintKeyResponse> {
    const payload: MintKeyRequest = {
        oauthAction: OauthActions.MintKey,
        firebaseToken: await getDbToken(),
        name: name?.trim() || undefined,
        requestId: Date.now()
    }

    const response = await makeApiPostRequest(JSON.stringify(payload));
    if (isCoplayResponse(response.status)) {
        const mintKeyResponse: MintKeyResponse = await response.json() as MintKeyResponse;
        return mintKeyResponse;
    } else {
        const text = await response.text();
        throw new Error("Failed to mint key: " + text);
    }
}

export async function deleteApiKey(keyid: string): Promise<BasicResponse> {
    const payload: DeleteKeyRequest = {
        oauthAction: OauthActions.DeleteKey,
        firebaseToken: await getDbToken(),
        keyId: keyid,
        requestId: Date.now()
    }

    const response = await makeApiPostRequest(JSON.stringify(payload));
    if (isCoplayResponse(response.status)) {
        return await response.json() as BasicResponse;
    } else {
        const text = await response.text();
        throw new Error("Failed to delete key: " + text);
    }
}
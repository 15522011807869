import { ChevronDownIcon } from "@chakra-ui/icons";
import { Menu, MenuButton, Button, MenuList, MenuOptionGroup, MenuItemOption, Flex, Input } from "@chakra-ui/react";
import { XboxUsersSet } from "lib/coplay/types/FrontendTypes";

export interface FilterState {
    consoleStatus: string[];
    userStatus: string[];
    profileNames: string[];
    notesContains: string;
}

export function DashboardFilter({ filters, setFilters, users }: {
    filters: FilterState,
    setFilters: (filters: FilterState) => void,
    users: XboxUsersSet
}) {
    // Get unique values for each filter type
    let profileNames = new Set<string>()
    let consoleStatuses = new Set<string>()
    let userStatuses = new Set<string>()

    for (let user of users) {
        if (user.xmProfile) {
            profileNames.add(user.xmProfile.name)
        }
        consoleStatuses.add(user.consoleState || 'None')
        userStatuses.add(user.userStatus.statusMessage)

    }

    const clearAllFilters = () => {
        setFilters({
            consoleStatus: [],
            userStatus: [],
            profileNames: [],
            notesContains: ''
        });
    };

    const isClearDisabled = Object.values(filters).every(
        (filter) => Array.isArray(filter) ? filter.length === 0 : filter === ''
    );

    return (
        <Menu closeOnSelect={false} >
            <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                {/* HACK */}
                Filters ({Object.values(filters).flat().length + (filters.notesContains ? 0 : -1)})
            </MenuButton>
            <MenuList maxHeight="350px" overflowY="auto" zIndex={3}>
                <Flex justifyContent="flex-end">
                    <Button
                        size="xs"
                        variant="ghost"
                        onClick={clearAllFilters}
                        isDisabled={isClearDisabled}
                        colorScheme="red"
                    >
                        Clear Filters
                    </Button>
                </Flex>
                <MenuOptionGroup title="Profile Names" type="checkbox"
                    value={filters.profileNames}
                    onChange={(values) => setFilters({ ...filters, profileNames: values as string[] })}>
                    <MenuItemOption value="None">None</MenuItemOption>
                    {Array.from(profileNames).map(name => (
                        <MenuItemOption key={name} value={name}>{name}</MenuItemOption>
                    ))}
                </MenuOptionGroup>

                <MenuOptionGroup title="Console Status" type="checkbox"
                    value={filters.consoleStatus}
                    onChange={(values) => setFilters({ ...filters, consoleStatus: values as string[] })}>
                    {Array.from(consoleStatuses).map(status => (
                        <MenuItemOption key={status} value={status}>{status}</MenuItemOption>
                    ))}
                </MenuOptionGroup>

                <MenuOptionGroup title="User Status" type="checkbox"
                    value={filters.userStatus}
                    onChange={(values) => setFilters({ ...filters, userStatus: values as string[] })}>
                    {Array.from(userStatuses).map(status => (
                        <MenuItemOption key={status} value={status}>{status}</MenuItemOption>
                    ))}
                </MenuOptionGroup>

                <MenuOptionGroup title="Notes Contains" type="checkbox">
                    <Flex px={3} py={2}>
                        <Input
                            placeholder="Search text"
                            value={filters.notesContains}
                            onChange={(e) => setFilters({ ...filters, notesContains: e.target.value })}
                            size="sm"
                        />
                    </Flex>
                </MenuOptionGroup>
            </MenuList>
        </Menu>
    );
}
import { Spinner, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, Flex, Progress, Text, useBreakpointValue } from "@chakra-ui/react";
import { subscribeToConsoleDetails } from "lib/coplay/backend/FirebaseFunctions";
import { LoadingProgressBar } from "lib/coplay/components/Loading";
import { ConsoleCrumb, XboxConsole, XboxUser } from "lib/coplay/types/BackendTypes";
import { useState, useEffect } from "react";
import { linkConsole, refreshConsoles } from "../../../../backend/ApiRequests";
import { ConsoleDetailsHeader } from "./ConsoleDetailsHeader";
import { ConsoleDetailsList } from "./ConsoleDetailsList";


enum ConsoleDetailsState {
    LOADING = "LOADING",
    ERROR = "ERROR",
    SUCCESS = "SUCCESS"
}

export const ConsoleDetailsModal = (props: {
    emailAddress: string,
    isOpen: boolean,
    onClose: () => void,
    crumb?: ConsoleCrumb
}) => {
    const [progress, setProgress] = useState<ConsoleDetailsState>(ConsoleDetailsState.LOADING);
    const [consoleDetails, setConsoleDetails] = useState<XboxConsole>();

    useEffect(() => {
        const unsub = subscribeToConsoleDetails(props.emailAddress, (console: XboxConsole) => {
            setConsoleDetails(console);
            setProgress(ConsoleDetailsState.SUCCESS);
        })
        return unsub

    }, [])

    const onClose = () => {
        props.onClose();
    }

    const unlinkConsole = () => {
        console.log("Unlinking Console");
        if (consoleDetails === undefined) {
            console.log("No Console to unlink")
            return;
        }

        linkConsole(props.emailAddress, consoleDetails.id, false).then((result) => {
            onClose();
        }).catch((error) => {
            console.log(error);
            setProgress(ConsoleDetailsState.ERROR);
        })
        setProgress(ConsoleDetailsState.LOADING);
    }

    const isLargeScreen = useBreakpointValue({ base: false, md: true });

    const states = {
        LOADING: <LoadingProgressBar />,
        ERROR: <>Error</>,
        SUCCESS:
            (<Flex direction={isLargeScreen ? 'row' : 'column'}>
                <ConsoleDetailsHeader crumb={props.crumb} onUnlink={unlinkConsole} emailAddress={props.emailAddress} console={consoleDetails} />
                <ConsoleDetailsList emailAddress={props.emailAddress} console={consoleDetails} />
            </Flex>),
    }


    return (
        <Modal isOpen={props.isOpen} onClose={onClose} size={isLargeScreen ? '6xl' : 'md'}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Console Details</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {states[progress]}
                </ModalBody>

                <ModalFooter>
                    <Button onClick={props.onClose} variant='ghost'>Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

import { Box, Button, Flex, HStack, Spacer, Text, useColorModeValue, VStack, Progress, IconButton, Menu, MenuButton, MenuItem, MenuList, Spinner } from "@chakra-ui/react";
import { refreshConsoles, batchTogglePower } from "lib/coplay/backend/ApiRequests";
import { ConsoleCrumb, XboxConsole, XboxState, XboxPower } from "lib/coplay/types/BackendTypes";
import { useState } from "react";
import { XboxIcon } from "../XboxIcon";
import { XboxConsoleName } from "./XboxConsoleName";
import { FaUnlink, FaSync, FaPowerOff, FaMoon, FaSun } from 'react-icons/fa'; // Import icons
import { MdOutlineSettingsPower, MdSettingsPower } from "react-icons/md";
import { IoMoonOutline } from "react-icons/io5";
import { IoMdPower } from "react-icons/io";

function bytesToGB(bytes: number) {
    const bytesPerGB = 1024 * 1024 * 1024; // = 2^30
    return (bytes / bytesPerGB).toFixed(2);
}

export const ConsoleDetailsHeader = (props: {
    emailAddress: string,
    console: XboxConsole,
    onUnlink: () => void,
    crumb?: ConsoleCrumb
}) => {

    const iconColor = useColorModeValue('#000000', 'white');
    const [isRefreshing, setIsRefreshing] = useState(false);

    const regex = /(?<=[a-z])(?=[A-Z])/;
    const xboxType = props.console.consoleType.split(regex).join(' ');

    const storageDevices = () => {
        if (props.console.storageDevices) {
            return props.console.storageDevices.map((dev) => {
                const totalSpaceGB = Number(bytesToGB(dev.totalSpaceBytes));
                const freeSpaceGB = Number(bytesToGB(dev.freeSpaceBytes));
                const usedSpaceGB = totalSpaceGB - freeSpaceGB;
                const usedPercentage = (usedSpaceGB / totalSpaceGB) * 100;

                return (
                    <Box key={dev.storageDeviceName}>
                        <Text fontWeight="bold">{dev.storageDeviceName}:</Text>
                        <Text>{freeSpaceGB} GB Free ({totalSpaceGB} GB Total)</Text>
                        <Progress w="100%" backgroundColor="gray.200" value={usedPercentage} size="sm" colorScheme="green" />
                    </Box>
                );
            });
        }
    };

    const refreshConsole = () => {
        setIsRefreshing(true);
        refreshConsoles([props.emailAddress]).then((result) => {
            setIsRefreshing(false);
        }).catch((error) => {
            console.log(error);
            setIsRefreshing(false);
        });
    };

    const togglePower = async (powerState: XboxPower) => {
        try {
            setIsRefreshing(true);

            await batchTogglePower([props.emailAddress], powerState);
        } catch (error) {
            console.error('Failed to toggle power:', error);
        } finally {
            setIsRefreshing(false);
        }
    };

    return (
        <VStack alignItems="center" spacing={4} w='300px'>
            <XboxConsoleName console={props.console} emailAddress={props.emailAddress} />
            <Box m={0} w="85%">
                <HStack my={-1} >
                    <Text><Text as="span" fontWeight="bold">Power: </Text>{props.console.powerState}</Text>
                    <Spacer />
                    <Menu>
                        <MenuButton
                            as={IconButton}
                            size="sm"
                            _hover={{ bg: 'secondaryGray.200' }}
                            variant='outline'
                            icon={isRefreshing ? <Spinner size="sm" /> : <MdSettingsPower />}
                            aria-label="Power Options"
                            isDisabled={isRefreshing}
                        />
                        <MenuList>
                            <MenuItem icon={<IoMdPower />} onClick={() => togglePower(XboxPower.WakeUp)}>
                                Wake up
                            </MenuItem>
                            <MenuItem icon={<IoMoonOutline />} onClick={() => togglePower(XboxPower.TurnOff)}>
                                Sleep
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </HStack>
            </Box>
            <XboxIcon color={iconColor} consoleState={props.crumb?.consoleState} />
            <Flex flexDirection="column" alignItems="flex-start" w="100%">
                <HStack mb={4} mt={0} justifyContent="center" width="100%">
                    <Button onClick={props.onUnlink} size="xs" py={'15px'} colorScheme="red" variant="solid" leftIcon={<FaUnlink />}>
                        Unlink Console
                    </Button>
                    <Button onClick={refreshConsole} isLoading={isRefreshing} size="xs" py={'15px'} colorScheme="green" variant="solid" leftIcon={<FaSync />}>
                        Refresh Console
                    </Button>

                </HStack>
                <ConsoleStatus errorCode={props.crumb?.consoleState} errorMessage={props.crumb?.consoleMsg} />

                <Box w="85%">
                    <Text fontWeight="bold">Type</Text>
                    <Text>{xboxType}</Text>
                </Box>
                <Box w="85%">
                    <Text fontWeight="bold">ID</Text>
                    <Text>{props.console.id}</Text>
                </Box>

                <Box w="85%">
                    <Text fontWeight="bold">Updated at:</Text>
                    <Text>{new Date(props.console.aggregatedAt).toLocaleString()}</Text>
                </Box>
                <Box w="85%">
                    {storageDevices()}
                </Box>
            </Flex>

            <Spacer />
        </VStack>
    );
};

export const ConsoleStatus = ({ errorCode, errorMessage }: { errorCode?: string, errorMessage?: string }) => {
    let text = "";
    if (errorCode != XboxState.OK) {
        text = errorMessage ?
            `${errorCode} : ${errorMessage}` :
            `${errorCode} : Your console may be offline or remote management may be disabled. Please check your console and try again`;
    } else {
        text = errorCode;
    }

    return (
        <Box w="85%">
            <Text fontWeight="bold">Status</Text>
            <Text>{text}</Text>
        </Box>
    );
};